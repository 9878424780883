











































.input {
    width: 100%;
}
